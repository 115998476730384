<template>
	<div class="flex flex-row flex-wrap justify-start items-start w-screen sm:w-auto h-full px-4 py-6 sm:px-6 sm:py-12">
		<!-- Назад -->
		<a @click="back" class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl mb-4">
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</a>

		<!-- Заголовок -->
		<h1 class="flex w-full text-xl mb-4 sm:mb-7 font-extrabold sm:text-2xl">
			{{ nameMO }}
		</h1>

		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<div class="table w-full rounded-lg bg-white">
				<!-- Заголовки -->
				<div class="thead content-center flex px-4 sm:px-8 sm:py-5 border-b sm:border-gray-100">
					<div v-for="(item, key) in fields" :key="'A' + key" class="sm:relative flex w-full" :class="{ 'sm:w-4/12': key === 0, 'sm:w-2/12': key != 0 }">
						<span
							class="table-cell w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"
							:class="{ 'text-blue-500 underline': filterBy === key }"
							@click="switchFilterVisibility(key)"
						>
							<!-- :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }" -->
							<span>
								{{ item.Name }}
							</span>
							<!-- eslint-disable max-len -->
							<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
									fill="#9193A5"
								/>
							</svg>
							<!-- eslint-enable max-len -->
						</span>
						<!-- Фильтр и сортировка -->
						<div class="fixed flex left-0 right-0 bottom-0 top-0 bg-black50 sm:bg-white sm:rounded-xl sm:absolute sm:top-20 sm:left-6 z-50" v-if="filterOpen === key">
							<form class="flex m-auto flex-col items-start w-72 py-5 bg-white rounded-xl shadow-lg popup-filter" onsubmit="return false;">
								<h3 class="flex px-5 text-gray-400 font-bold uppercase justify-between items-center w-full">
									<span>Сортировка</span>
									<svg @click="switchFilterVisibility(key)" width="28" class="sm:hidden" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.5625 17.5625L17.5625 10.5625" stroke="#3377FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5625 17.5625L10.5625 10.5625" stroke="#3377FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</h3>
								<label class="mt-4 px-5 cursor-pointer font-normal">
									По возрастанию (А-Я)
									<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(filterData)" v-model="filterData.sortBy" value="asc" />
								</label>
								<label class="mt-4 px-5 cursor-pointer font-normal">
									По убыванию (Я-А)
									<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(filterData)" v-model="filterData.sortBy" value="desc" />
								</label>
								<h3 class="flex items-center w-full mt-5 pt-5 px-5 border-t border-gray-400 text-gray-400 font-bold uppercase">
									Фильтр
									<button type="reset" class="ml-auto text-xs font-normal text-blue-500 underline normal-case cursor-pointer" @click="resetFilter">Сбросить фильтр</button>
								</h3>
								<input
									type="text"
									v-model="filterData.searchValue"
									v-filter="item.paramsFiter"
									@input="search(filterData)"
									placeholder="Введите текст..."
									class="flex flex-grow mt-10 ml-3 pl-2 w-64 outline-none border-b border-gray-400"
								/>
							</form>
						</div>
					</div>
				</div>

				<!-- Раскрывающий список последней активности для мобильных -->
				<div class="trow">
					<div
						v-for="(item, rowKey) in list"
						:key="'D' + rowKey"
						:class="{ active: item.flagOpen }"
						class="w-full justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0"
					>
						<span class="table-cell" :class="{ 'text-gray-500': !item.flagOpen, 'text-white bg-blue-500': item.flagOpen }">
							<span class="flex-grow w-11/12">{{ item.fullName }}</span>
							<svg
								@click="item.flagOpen = !item.flagOpen"
								class="flex-grow-0 mt-1 w-1/12"
								:class="{ 'transform rotate-180': item.flagOpen }"
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1"
									:stroke="item.flagOpen ? '#fff' : '#9193A5'"
									stroke-width="2"
									stroke-linecap="round"
								/>
							</svg>
						</span>

						<div v-show="item.flagOpen" class="sm:hidden bg-liteGray">
							<!-- 1.1 сайт -->
							<div class="pr-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<span class="table-cell text-gray-500 w-3/4"> 1.1 (с) </span>
								<span class="text-center leading-10 text-blue-500 bg-gray-100 rounded-full w-10 text-sm font-semibold">
									{{ item.res_1_1_2 }}
								</span>
								<router-link :to="{ name: 'Form', params: { anketaResultId: item.id, order: 2 } }" class="text-center leading-10 text-sm font-semibold">
									<svg class="transform -rotate-90" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
									</svg>
								</router-link>
							</div>
							<!-- 1.1 информационный стенд -->
							<div class="pr-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<span class="table-cell text-gray-500 w-3/4"> 1.1 (п) </span>
								<span class="text-center leading-10 text-blue-500 bg-gray-100 rounded-full w-10 text-sm font-semibold">
									{{ item.res_1_1_1 }}
								</span>
								<router-link :to="{ name: 'Form', params: { anketaResultId: item.id, order: 1 } }" class="text-center leading-10 text-sm font-semibold">
									<svg class="transform -rotate-90" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
									</svg>
								</router-link>
							</div>
							<!-- 1.2 -->
							<div class="pr-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<span class="table-cell text-gray-500 w-3/4"> 1.2 </span>
								<span class="text-center leading-10 text-blue-500 bg-gray-100 rounded-full w-10 text-sm font-semibold">
									{{ item.res_1_2 }}
								</span>
								<router-link :to="{ name: 'Form', params: { anketaResultId: item.id, order: 3 } }" class="text-center leading-10 text-sm font-semibold">
									<svg class="transform -rotate-90" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
									</svg>
								</router-link>
							</div>
							<!-- 3.1 -->
							<div class="pr-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<span class="table-cell text-gray-500 w-3/4"> 3.1 </span>
								<span class="text-center leading-10 text-blue-500 bg-gray-100 rounded-full w-10 text-sm font-semibold">
									{{ item.res_3_1 }}
								</span>
								<router-link :to="{ name: 'Form', params: { anketaResultId: item.id, order: 4 } }" class="text-center leading-10 text-sm font-semibold">
									<svg class="transform -rotate-90" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
									</svg>
								</router-link>
							</div>
							<!-- 3.2 -->
							<div class="pr-5 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<span class="table-cell text-gray-500 w-3/4"> 3.2 </span>
								<span class="text-center leading-10 text-blue-500 bg-gray-100 rounded-full w-10 text-sm font-semibold">
									{{ item.res_3_2 }}
								</span>
								<router-link :to="{ name: 'Form', params: { anketaResultId: item.id, order: 5 } }" class="text-center leading-10 text-sm font-semibold">
									<svg class="transform -rotate-90" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
									</svg>
								</router-link>
							</div>
							<!-- Кнопка -->
							<div class="px-5 pb-2 sm:p-0 w-full sm:w-3/12 flex justify-between items-center cursor-pointer border-b border-gray-100 sm:border-0">
								<button @click="openModal(idMo, item, item.isSelected ? false : true)" class="btn">
									<span v-if="item.isSelected">Отменить</span>
									<span v-else>Выбрать</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="fade">
			<div v-if="isModalVisible" class="absolute top-2/4 left-0">
				<!-- <div @click="isModalVisible = !isModalVisible" class="absolute bg-black opacity-20 inset-0 z-0" /> -->
				<div class="w-full max-w-lg p-3 rounded-xl shadow-2xl bg-white">
					<div>
						<div class="text-center p-3 flex-auto justify-center leading-6">
							<h2 class="text-2xl font-bold py-4">{{ modal.title }}</h2>
							<p class="text-md text-gray-500 px-8">
								{{ modal.text }}
							</p>
						</div>
						<div v-show="modal.viewBlockBtn" class="p-3 mt-2 text-center space-x-4 sm:block">
							<button
								v-show="modal.viewBtnSave"
								@click="setMOResult(selectedUser, YesNo)"
								:disabled="loadingBtnSetResultAnkets"
								class="mb-2 sm:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100"
							>
								<span>Да</span>
							</button>
							<button
								:disabled="loadingBtnSetResultAnkets"
								v-show="modal.viewBtnCancel"
								@click="isModalVisible = !isModalVisible"
								class="mb-2 sm:mb-0 bg-blue-500 border border-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-purple-600"
							>
								Закрыть
							</button>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
	name: 'MobileInfoView',
	data() {
		return {
			loadingBtnSetResultAnkets: false,
			modal: {
				title: '',
				text: '',
				viewBlockBtn: false,
				viewBtnSave: false,
				viewBtnCancel: false
			},
			selectedMOID: null,
			YesNo: null,
			isModalVisible: false,
			selectedUser: null,
			nameMO: '',
			medicalId: null,
			block0: '',
			block1: '',
			block2: '',
			block3: '',
			block4: '',
			countCompletedBlocks: '',
			idMo: null,
			filterOpen: null,
			filterBy: null,
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			listDefault: [],
			list: [],
			fields: [
				{
					Name: 'Сотрудник ОС по НОК',
					paramsFiter: '[a-zA-Zа-яА-Я]'
				}
			],
			url: 'OS/GetControlStatByMO'
		};
	},
	methods: {
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		resetFilter() {
			this.filterData = {
				sortBy: '',
				searchValue: ''
			};
			this.getData();
		},
		search: debounce(function (obj) {
			if (!obj.searchValue) {
				this.list = this.listDefault;

				return;
			}
			setTimeout(() => {
				if (obj.sortBy && !obj.searchValue) {
					this.list = this.listDefault.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA);
				} else if (!obj.sortBy && obj.searchValue) {
					this.list = this.listDefault.filter((f) => f.fullName.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);
				} else if (obj.sortBy && obj.searchValue) {
					let res = [];
					res = this.listDefault.filter((f) => f.fullName.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);

					this.list = res.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA);
				}
			}, 100);
		}, 1000),
		sortFilter(obj) {
			if (obj.sortBy && !obj.searchValue) {
				this.list = this.listDefault.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA);
			} else if (!obj.sortBy && obj.searchValue) {
				this.list = this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);
			} else if (obj.sortBy && obj.searchValue) {
				let res = [];
				res = this.listDefault.filter((f) => f.name.toLowerCase().indexOf(obj.searchValue.toLowerCase()) > -1);

				this.list = res.sort(obj.sortBy === 'asc' ? this.compareAB : this.compareBA);
			}
		},
		compareAB(a, b) {
			if (a.fullName < b.fullName) {
				return -1;
			}
			if (a.fullName > b.fullName) {
				return 1;
			}
			return 0;
		},
		compareBA(a, b) {
			if (a.fullName < b.fullName) {
				return 1;
			}
			if (a.fullName > b.fullName) {
				return -1;
			}
			return 0;
		},
		getData() {
			this.$http.get(`${this.url}?medicalId=${this.medicalId}`).then((res) => {
				let response = res;
				for (let i = 0; i < response.data.length; i += 1) {
					response.data[i].flagOpen = false;
				}

				this.listDefault = response.data;
				this.list = response.data;
			});
		},
		test() {
			console.log(123);
		},
		openModal(idMO, user, flag) {
			this.modal = {
				title: 'Вы уверены ?',
				text: flag ? 'Что хотите изменить итоговое значение МО на выбранные результаты?' : 'Что хотите отменить выбранное итоговое значение МО?',
				viewBlockBtn: true,
				viewBtnSave: true,
				viewBtnCancel: true
			};
			this.isModalVisible = true;
			this.selectedUser = user;
			this.selectedMOID = idMO;
			this.YesNo = flag;
		},
		setMOResult(user, YesNo) {
			this.loadingBtnSetResultAnkets = true;

			this.$http
				.post(`/OS/${YesNo ? '' : 'Un'}SetMOResult?id=${user.id}`)
				.then((res) => {
					if (res.status === 200) {
						for (let i = 0; i < this.list.length; i += 1) {
							if (this.list[i].id === this.selectedUser.id) {
								this.list[i].isSelected = !this.list[i].isSelected;
							} else {
								this.list[i].isSelected = false;
							}
						}

						// this.selectedUser = null;
						this.selectedMOID = null;
						this.loadingBtnSetResultAnkets = false;

						this.modal = {
							title: 'Успешно!',
							text: `Итоговое значение МО было ${YesNo ? 'изменено на выбранные результаты' : 'отменено'}`,
							viewBlockBtn: true,
							viewBtnSave: false,
							viewBtnCancel: true
						};
					}
				})
				.catch((error) => {
					if (error) {
						this.loadingBtnSetResultAnkets = false;
						this.modal = {
							title: 'Ошибка!',
							text: 'Обратитесь к Администратору!',
							viewBlockBtn: true,
							viewBtnSave: false,
							viewBtnCancel: true
						};
					}
				});
		},
		back() {
			for (let i = 0; i < this.list.length; i += 1) {
				if (this.list[i].isSelected) {
					this.block0 = this.list[i].res_1_1_2;
					this.block1 = this.list[i].res_1_1_1;
					this.block2 = this.list[i].res_1_2;
					this.block3 = this.list[i].res_3_1;
					this.block4 = this.list[i].res_3_2;

					break;
				}
			}

			this.$router.push({
				path: '/osmember/infoMo/mo',
				query: {
					nameMO: this.nameMO,
					countCompletedBlocks: this.countCompletedBlocks,
					medicalId: this.medicalId,
					block0: this.block0,
					block1: this.block1,
					block2: this.block2,
					block3: this.block3,
					block4: this.block4,
					idMo: this.idMo
				}
			});
		}
	},
	created() {
		if (this.$route.query.nameMO) this.nameMO = this.$route.query.nameMO;
		if (this.$route.query.medicalId) this.medicalId = this.$route.query.medicalId;
		if (this.$route.query.idMo) this.idMo = this.$route.query.idMo;
		if (this.$route.query.countCompletedBlocks) this.countCompletedBlocks = this.$route.query.countCompletedBlocks;

		if (this.$route.query.block0 || this.$route.query.block1 || this.$route.query.block2 || this.$route.query.block3 || this.$route.query.block4) {
			for (let i = 0; i < 5; i += 1) {
				this[`block${i}`] = this.$route.query[`block${i}`];
			}
		}

		this.getData();
	}
};
</script>

<style>
.table-cell {
	@apply flex flex-wrap p-5 px-7 text-sm text-left;
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}
</style>
